<template>
	<div v-if="postObjectMutated" class="container mt-4">
		<h2>Update Post</h2>
		<div class="row pt-3 pb-1">
			<div class="col-12">
				<drag-and-drop
					title="Drop the cover image here!"
					subtitle="One image only."
					:images="images"
					count="1"
				/>
			</div>
		</div>

		<div class="row pt-1 pb-1">
			<div class="col-4">
				<select v-model="postObjectMutated.category" class="form-control">
					<option disabled selected :value="null">Pick the category</option>
					<option v-for="category in categories" :value="category.name">{{ category.name }}</option>
				</select>
			</div>
			<div class="col-8">
				<input v-model="postObjectMutated.title" class="form-control" type="text" placeholder="Title here...">
			</div>
		</div>

		<div class="row pt-1 pb-1">
			<div class="col-12">
				<input v-model="postObjectMutated.description" class="form-control" type="text" placeholder="Short description here...">
			</div>
		</div>

		<div class="row pt-1 pb-1">
			<div class="col-12 text-left">
				<toggle-switch id="publish" label="Publish" :checked="published" :disabled="loading" @change="publish" />
			</div>
		</div>

		<div class="row pt-1 pb-1 mt-5">
			<div class="col-12 text-left">
				<h3>Components</h3>
				<hr>
			</div>
		</div>

		<div class="row pt-1 pb-1">
			<div class="col-12">
				<div class="form-group" v-for="(component, index) in postObjectMutated.content">
					<button @click="removeComponent(index)" class="btn btn-danger mb-2 mt-5">Remove</button>
					<section-heading
						v-if="component.type === consti.postComponents.heading"
						:component-data="component"
						:key="makeRandomID(9) + component.toString()"></section-heading>
					<paragraph
						v-else-if="component.type === consti.postComponents.paragraph"
						:component-data="component"
						:key="makeRandomID(9) + component.toString()"></paragraph>
					<code-snippet
						v-else-if="component.type === consti.postComponents.code"
						:component-data="component"
						:key="makeRandomID(9) + component.toString()"></code-snippet>
					<drag-and-drop
						v-else-if="component.type === consti.postComponents.image"
						title="Drop the images here!"
						subtitle="No more than 10 images."
						count="10"
						:images="component.carousel_image"
						:key="makeRandomID(9) + component.toString()"></drag-and-drop>
				</div>
			</div>
		</div>

		<div id="scrollTarget" class="row pt-1 pb-1">
			<div class="col-4"></div>
			<div class="col-4">
				<div class="container-fluid">
					<div class="row">
						<div class="col-6">
							<add-section-button @sectionSelected="addComponent"></add-section-button>
						</div>
						<div class="col-6">
							<button @click="updatePost" type="button" class="btn btn-success"><span v-if="loading" class="spinner-border spinner-border-sm"></span>
								Save</button>
						</div>
					</div>
				</div>
			</div>
			<div class="col-4"></div>
		</div>
	</div>
</template>

<script>

import DragAndDrop from "../components/DragAndDrop"
import request from '@/request'
import consti from '@/const'
import AddSectionButton from "../components/AddSectionButton"
import _ from 'underscore'
import cloneDeep from 'lodash.clonedeep'
import SectionHeading from "../components/SectionHeading";
import Paragraph from "../components/Paragraph";
import CodeSnippet from "../components/CodeSnippet";
import ImageCarousel from "../components/ImageCarousel";
import ToggleSwitch from "../components/ToggleSwitch";

export default {
	name: "UpdatePost",
	components: {
		ToggleSwitch,
		ImageCarousel,
		CodeSnippet,
		Paragraph,
		SectionHeading,
		AddSectionButton,
		DragAndDrop
	},
	created() {
		this.postId = this.$route.params.post

		if (!this.postId) {
			alert('Post not found')
		}

		let _this = this
		_.mixin({
			diff(_new, _old) {
				let _res = {};

				_this.calculateDeepDiff(_new, _old, _res);
				_this.calculateDeepDiff(_old, _new, _res);

				return _res;
			}
		})

		this.getPost()
	},
  mounted() {
    document.title = 'BMS | Update Post'
  },
	data() {
		return {
			images: [],
			coverImage: null,

			postObject: {},
			postObjectMutated: {},

			categories: [],

			postId: null,

			loading: false,
			consti: consti,

			published: false
		}
	},
	methods: {
		getPost() {
			this.loading = true
			request.get(consti.baseUrl + consti.categories, this.$router)
				.then(data => {
					this.categories = data

					request.get(consti.baseUrl + consti.posts + '/' + this.postId, this.$router)
						.then(data => {
							this.loading = false
							this.setPostObject(data)
						}, error => {
							this.loading = false
						})
				}, error => {
					this.loading = false
				})
		},
		setPostObject(data) {

			this.postObject.title = data.title
			this.postObject.description = data.description
			this.postObject.category = data.category.name
			this.postObject.content = JSON.parse(data.content) ?? []
			this.published = data.published

			if (data.cover_image) {
				this.postObject.cover_image = data.cover_image
				this.images = [this.postObject.cover_image]
			}

			this.postObjectMutated = cloneDeep(this.postObject)
		},
		calculatePayload() {
			let payload = {}

			let components = cloneDeep(this.postObjectMutated.content)

			delete this.postObject.content
			delete this.postObjectMutated.content

			this.calculateDeepDiff(this.postObjectMutated, this.postObject, payload)

			if (components) {
				payload.content = JSON.stringify(components)
			}

			return payload
		},
		calculateDeepDiff(_new, _old, _res) {
			_.each(_new, function(v, k) {

				if (_res.hasOwnProperty(k) || _old[k] === v) {
					return
				}

				_res[k] = _.isObject(v) ? _.diff(v, _old[k]) : v
			})
		},
		addComponent(type) {

			let component = null

			switch (type) {
				case consti.postComponents.heading:
					component = {
						type: type,
						[type]: {
							text: null
						}
					};
					break
				case consti.postComponents.paragraph:
					component = {
						type: type,
						[type]: {
							type: null,
							text: null
						}
					};
					break
				case consti.postComponents.code:
					component = {
						type: type,
						[type]: {
							language: null,
							code: null
						}
					};
					break
				case consti.postComponents.image:
					component = {
						type: type,
						[type]: []
					};
					break
				default:
					break
			}

			this.postObjectMutated.content.push(component)
		},
		removeComponent(index) {
			this.postObjectMutated.content.splice(index, 1)
		},
		makeRandomID(length) {
			let result = '';
			let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			let charactersLength = characters.length;
			for (let i = 0; i < length; ++i) {
				result += characters.charAt(Math.floor(Math.random() * charactersLength));
			}

			return result;
		},
		updatePost() {
			let payload = this.calculatePayload()

			payload.cover_image = this.images.length > 0 ?
				this.images[0] !== this.postObject.cover_image ?
					this.images[0] :
					'' :
				null

			if (payload.cover_image === '') {
				delete payload.cover_image
			}

			let formData = new FormData()

			for (let key in payload) {
				formData.append(key, payload[key] ?? null);
			}

			formData.append('_method', 'PUT')

			this.loading = true
			request.put(consti.baseUrl + consti.posts + '/' + this.postId, payload, this.$router)
				.then(data => {
					this.loading = false
					this.setPostObject(data)

          setTimeout(() => {
            this.scrollToBottomOfElement()
          }, 500)
				}, error => {
					this.loading = false
				})
		},
		publish() {
			this.loading = true

			request.put(consti.baseUrl + consti.posts + '/' + this.postId, {published: !this.published}, this.$router)
				.then(data => {
					this.loading = false
					this.published = data.published
				}, error => {
					console.log(error)
					this.loading = false
				})
		},
    scrollToBottomOfElement() {
      const element = document.getElementById('scrollTarget')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
	}
}
</script>

<style scoped>

</style>
