<template>
	<div class="custom-control custom-switch">
		<input
			type="checkbox"
			class="custom-control-input"
			:id="id"
			:checked="checked"
			:disabled="disabled"
			@change="$emit('change', $event.target.checked)">
		<label class="custom-control-label" :for="id">{{ label }}</label>
	</div>
</template>

<script>
export default {
	name: "ToggleSwitch",
	props: {
		id: {
			type: String,
			required: true
		},
		label: {
			type: String,
			required: true
		},
		checked: {
			type: Boolean,
			required: true
		},
		disabled: {
			type: Boolean,
			required: true
		}
	},
	emits: ['change']
}
</script>

<style scoped>

</style>